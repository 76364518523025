import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";

const cname = "fawuc";
function UserConsent() {
  const [open, setOpen] = useState(false);
  let flag = true;
  if (checkConsentFlag() === "true") {
    flag = true;
  }
  const [state, setState] = useState(flag);
  const setConsentFlag = () => {
    window.localStorage.setItem(cname, "true");
    setState(true);
  };

  const handleAgree = () => {
    storeValues();
    window.localStorage.setItem(cname, "true");
    setState(true);
  };

  const handleDecline = () => {
    setState(true);
  };

  if (state) {
    return "";
  } else {
    return (
      <div
        id="gdpr_cookie_info_bar"
        className="gdpr-align-center gdpr_infobar_postion_bottom"
      >
        <div className="gdpr-info-bar-container">
          <div className="gdpr-info-bar-content">
            <div className="gdpr-cookie-notice">
              <p>
                This website uses cookies to improve the functionality,
                performance, and effectiveness of our user experience. For more
                information on our data policies, please visit our &nbsp;
                <a
                  className="gdpr-privacy-link"
                  href="http://futuretodayinc.com/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>Terms of Use</u>
                </a>
                &nbsp;&amp;&nbsp;
                <a
                  className="gdpr-privacy-link"
                  href="https://futuretodayinc.com/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                . By clicking "I Agree," you consent to the use of
                cookies.&nbsp;&nbsp;
                <a
                  className="gdpr-privacy-link gdpr-more-info"
                  role="button"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  {open ? "Hide Info" : "More Info"}
                </a>
              </p>
            </div>
            <div className="gdpr-button-holder">
              <button
                className="gdpr-infobar-allow-all"
                aria-label="I Agree"
                onClick={handleAgree}
              >
                <span className="ltr-zd4xih no-hover ">I Agree</span>
              </button>
              <button
                className="gdpr-infobar-dec-all "
                aria-label="Decline"
                onClick={handleDecline}
              >
                <span className="ltr-zd4xih no-hover">Decline</span>
              </button>
            </div>
          </div>
          <Collapse in={open}>
            <div id="example-collapse-text">
              <div className="">
                <div className="cookie-list-table-wrapper">
                  <table className="cookie-list-table">
                    <tbody>
                      <tr>
                        <th>Cookie Name</th>
                        <th>Cookie Value</th>
                      </tr>
                      <tr>
                        <td>_ga_EC1QRW2LV5</td>
                        <td>GS1.1.1664369756.1.1.1664369853.0.0.0</td>
                      </tr>
                      <tr>
                        <td>_ga</td>
                        <td>GA1.1.502665595.1664369854</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <button
          className="gdpr-close-button"
          id="gdprCloseButton"
          onClick={handleDecline}
        >
          X
        </button>
      </div>
    );
    return (
      <>
        <div id="cookieConsent" className="cookie-consent show-consent">
          <span className="cookie-text">
            We use cookies to ensure you have the best browsing experience on
            our website. By using our site, you acknowledge that you have read
            and understood our&nbsp;
            <a
              href="http://futuretodayinc.com/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Terms of Use</u>
            </a>
            &nbsp;&amp;&nbsp;
            <a
              href="http://futuretodayinc.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Privacy Policy</u>
            </a>
          </span>
          <button className="consent-btn" onClick={setConsentFlag}>
            Got It
          </button>

          {/* <div className="row">
            <div>
              <button className="consent-btn" onClick={handleAgree}>
                Agree
              </button>
              <button className="consent-btn" onClick={handleDecline}>
                Decline
              </button>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}
function storeValues() {}
function checkConsentFlag() {
  return window.localStorage.getItem(cname);
}

export default UserConsent;
