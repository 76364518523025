function ErrorPage() {
  return (
    <div className="container">
      <div className="mt-3">
        <h2>Error: Page not found..!</h2>
      </div>
    </div>
  );
}
export default ErrorPage;
