import React, { useState, useEffect } from "react";
function Footer() {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch("./assets/data/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      });
  }, []);
  let logoFawesome = "./assets/images/logo-fawesome.svg";

  return !data ? (
    ""
  ) : (
    <footer className="padVer">
      <div className="container">
        <div className="row">
          <div className="fawlogo">
            <img src={logoFawesome} alt="fawesome" />
          </div>
        </div>
        <div className="divfooter">
          <img
            className="tabConImg footer-divider"
            src="/assets/images/divfooter.svg"
            alt="footer divider"
          />
        </div>

        <div className="row mt-3">
          <div className="col-md-3 col-sm-12">
            <h4>Company</h4>
            <ul>
              <li>
                <a
                  href={data.footer_links.about}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href={data.footer_links.contact}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  Contact Us
                </a>
              </li>

              <li>
                <a
                  href={data.footer_links.terms}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  href={data.footer_links.privacy}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-12">
            <h4>Partners</h4>
            <ul>
              <li>
                <a
                  href={data.footer_links.advertise}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  Advertise with Us
                </a>
              </li>
              <li>
                <a
                  href={data.footer_links.partner}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  Partner with Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-12">
            <h4>Press</h4>
            <ul>
              <li>
                <a
                  href={data.footer_links.press}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  Press Releases
                </a>
              </li>
              <li>
                <a
                  href={`./blogs/`}
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  Blogs
                </a>
              </li>
              <li>
                <a
                  href={data.footer_links.news}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-lp gtm-footer-link"
                >
                  Fawesome in News
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="social-media-section">
              <h4>Follow Us</h4>
              <div className="social-icons">
                <ul>
                  <li>
                    <a
                      href="https://www.youtube.com/@fawesometv"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="gtm-lp gtm-footer-link"
                    >
                      <img
                        src="/assets/images/yt.png"
                        alt="YouTube"
                        className="social-logo"
                      />
                      <span>YouTube</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/fawesome_tv/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="gtm-lp gtm-footer-link"
                    >
                      <img
                        src="/assets/images/insta.png"
                        alt="Instagram"
                        className="social-logo"
                      />
                      <span>Instagram</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 d-apps">
          <h4>We are also available on</h4>
        </div>

        <div className="platIco">
          <a
            href="https://channelstore.roku.com/details/22e247eb7f697a465ef2d9719e52b495/fawesome"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              className="sm-no"
              src="./assets/images/app/ico-roku.webp"
              alt="Roku"
              loading="lazy"
            />
          </a>
          <a
            href="https://www.amazon.com/Future-Today-Inc-Movies-Fawesome-tv/dp/B076X8FKXP/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              className="sm-no"
              src="./assets/images/app/ico-fireTV.webp"
              alt="FireTV"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-xfinity.webp"
              alt="xfinity"
              loading="lazy"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/fawesome/id1063891742?platform=appleTV"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              className="sm-no"
              src="./assets/images/app/ico-appleTV.webp"
              alt="Apple TV"
              loading="lazy"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/fawesome/id1063891742?platform=iOS"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              className="sm-no"
              src="./assets/images/app/ico-iOS.webp"
              alt="iOS"
              loading="lazy"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.future.moviesByFawesomeAndroidTV&hl=en_US&gl=US"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              className="sm-no"
              src="./assets/images/app/ico-android.webp"
              alt="Android"
              loading="lazy"
            />
          </a>
          <a
            href="https://play.google.com/store/search?q=fawesome&c=apps"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-googleTV.svg"
              alt="GoogleTV"
              loading="lazy"
            />
          </a>
          <a
            href="https://www.microsoft.com/en-us/p/movies-by-fawesome/9p55dw09xk2d?activetab=pivot:overviewtab"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-windows.webp"
              alt="windows"
              loading="lazy"
            />
          </a>
          <a
            href="https://us.lgappstv.com/main/tvapp/detail?appId=458741"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-LG.webp"
              alt="LG"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-cox.webp"
              alt="COX"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-rogers.webp"
              alt="Rogers"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-shaw.webp"
              alt="Shaw"
              loading="lazy"
            />
          </a>
          <a
            href="https://www.amazon.com/Future-Today-Inc-Movies-Fawesome-tv/dp/B076X8FKXP"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-kindle.webp"
              alt="Kindle"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-sam.webp"
              alt="Samsung"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-vidaa.webp"
              alt="Vidaa"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-vizio.webp"
              alt="Vizio"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-xbox.webp"
              alt="XBOX"
              loading="lazy"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="footer-link gtm-lp gtm-footer-link"
          >
            <img
              src="./assets/images/app/ico-xumo.webp"
              alt="Xumo"
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
