import { useInView } from "react-intersection-observer";
import TilesSlider from "./TilesSlider";

function TilesSliderInView() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  return <div ref={ref}>{inView ? <TilesSlider /> : null}</div>;
}
export default TilesSliderInView;
