import { useInView } from "react-intersection-observer";

function AboutFTI() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  let browseIcon = "./assets/images/Frame.png";

  return (
    <div className="container mt-5">
      <div className="container-fluid">
        <div className="text-center pt-5 pb-5">
          <h2>About Future Today Inc.</h2>
        </div>
      </div>

      <div className="row" ref={ref}>
        {inView ? (
          <>
            <div className="col-12 col-md-3 col-lg-3 p-4 fti-left">
              <img
                src="./assets/images/FT.png"
                className="fti-logo"
                alt="Future Today Inc"
              />
            </div>
            <div className="col-12 col-md-9 col-lg-9 p-4 ">
              <div>
                Future Today Inc. offers state-of-the-art AVOD technology,
                making it easy for content creators to build ad-supported OTT
                channels on top CTV platforms. Advertisers can also reach
                targeted audiences in a secure, brand-safe setting.
              </div>
              <a
                href="https://futuretodayinc.com/about-us"
                target="_blank"
                className="btn btn-primary mt-2 learn-more-btn gtm-lp gtm-learn-more "
                rel="noopener noreferrer"
              >
                <img
                  src={browseIcon}
                  className="img-fluid"
                  alt="Learn More"
                  style={{ marginLeft: -20, width: 30 }}
                />
                Learn More
                {/* <span></span> */}
              </a>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default AboutFTI;
