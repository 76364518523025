import { useInView } from "react-intersection-observer";
import Footer from "./Footer";

function FooterInView() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  return <div ref={ref}>{inView ? <Footer /> : null}</div>;
}
export default FooterInView;
