import Lottie from "react-lottie";
import data from "./data.json";
import heroVideo from "../assets/media/FT-Website-Video-Jan-2022.mp4";

function LoaderAnim() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div id="loading">
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  );
}
export default LoaderAnim;
