import { useInView } from "react-intersection-observer";
import BlogTiles from "./BlogTiles";

function BlogTilesInview() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  return <div ref={ref}>{inView ? <BlogTiles /> : null}</div>;
}
export default BlogTilesInview;
