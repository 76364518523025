import { useInView } from "react-intersection-observer";
import HalloweenSlider from "./HalloweenSlider";

function HalloweenSliderInView() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  return <div ref={ref}>{inView ? <HalloweenSlider /> : null}</div>;
}
export default HalloweenSliderInView;
