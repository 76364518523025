import Slider from "react-slick";
import Trailer from "./Trailer";
function TrailerSlider() {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    // fade: true,
    // cssEase: "linear",
  };
  let data = [
    {
      title: "The Peanut Man:",
      sub_tile: "The True Story Of George Washington Carver",
      movie_title: "The Peanut Man",
      description:
        "Torn between his love for Sarah and his mission to save farmlands, George Washington Carver risks losing everything over his work. Facing racism and doubt, Carver works to unlock the secrets of the peanut that could transform agriculture.",
      image: "./assets/images/The-Peanut-Man.webp",
      node_id: "10640618",
    },
    {
      title: "Greenwood Rising: The Rise of Black Wall Street",
      movie_title: "Greenwood Rising: The Rise of Black Wall Street",
      description:
        "A must-watch historical drama that depicts the untold true story of O.W. Gurley and his wife, Emma, and their rise from humble beginnings. Together, they embark on a love-filled journey through one of the most adventurous chapters of Black history, overcoming racism and unjust laws to build the thriving community that became known as Black Wall Street.",
      image: "./assets/images/Greenwood-Rising_1920x1080.webp",
      node_id: "10635196",
    },
  ];
  return (
    <div className="container mt-4">
      <div className="container-fluid">
        <div className="text-center mb-2">
          <img
            className="img-fluid faw-orig"
            src="/assets/images/faw-orig-s.webp"
            alt="Fawesome Oirginals"
          />
        </div>

        <div>
          <Slider {...settings}>
            {data.map((item, key) => {
              return <Trailer data={item} key={key}></Trailer>;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
export default TrailerSlider;
