import { useInView } from "react-intersection-observer";
import FavouriteGenres from "./FavouriteGenres";

function FavouriteGenresInview() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  return <div ref={ref}>{inView ? <FavouriteGenres /> : null}</div>;
}
export default FavouriteGenresInview;
