import { useInView } from "react-intersection-observer";
import React from "react";
import Slider from "react-slick";

function NoSubscriptions() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  let settings = {
    dots: false, // Removed dots
    lazyLoad: "ondemand",
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    focusOnSelect: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //return <div ref={ref}>{inView ? <Footer /> : null}</div>;

  return (
    <div ref={ref}>
      {inView ? (
        <section id="noSubInStr" className="padVer">
          <div className="container">
            <div className="row">
              <h2>Our Partners</h2>
              <div className="slider-container">
                <Slider {...settings}>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-lio.svg"}
                      alt="Lionsgate"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-marvista.svg"}
                      alt="Marvista"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-filmrise.svg"}
                      alt="Filmrise"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-cinedigm.svg"}
                      alt="Cinedigm"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-cineflix.svg"}
                      alt="Cineflix"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-paramount.svg"}
                      alt="Paramount"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-sony.svg"}
                      alt="Sony"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-warner.svg"}
                      alt="Warner"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-shoutFactory.svg"}
                      alt="Shout Factory"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-samuelGoldwyn.svg"}
                      alt="Samuel Goldwyn Films"
                    />
                  </div>
                  <div className="imglogo">
                    <img
                      className="part-lio"
                      src={"./assets/images/ico-gravitasventrues.svg"}
                      alt="Gravitas Ventures"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
}

export default NoSubscriptions;
