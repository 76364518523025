import { useInView } from "react-intersection-observer";
import Trailer from "./Trailer";
import TrailerSlider from "./TrailerSlider";

function TrailerSliderInView() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  return <div ref={ref}>{inView ? <TrailerSlider /> : null}</div>;
}
export default TrailerSliderInView;
