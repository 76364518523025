function Tile({ item, key }) {
  return (
    <div
      className="titleItem"
      key={key}
      // onClick={() => {
      //   handleClick(item);
      // }}
    >
      <div
        className="titlePic gtm-lp gtm-title-thumbnail"
        onClick={() => {
          handleClick(item);
        }}
      >
        <img src={getImageUrl(item)} alt={item.title} />
      </div>
      {/* <div
        className="titleMeta gtm-lp gtm-title-thumbnail"
        onClick={() => {
          handleClick(item);
        }}
      >
        <h3>{item.title}</h3>
        <div className="metaMini">
          <div className="yearTime">
            ({item.year}) - {item.time}
          </div>
          <div className="rating">{item.rating}</div>
        </div>
      </div> */}
    </div>
  );

  function getImageUrl(item) {
    let imageUrl = `${window.cdnBaseUrl}${item.image}`;
    return imageUrl;
  }

  function handleClick(item) {
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    let newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    let title = getCleanedTitle(item.title);
    let nodeId = item.nodeId;
    let base = "/";
    let itemType = "movies";
    if (item.itemType && item.itemType.toLowerCase() === "tv-shows") {
      itemType = "tv-shows";
    } else if (item.itemType && item.itemType.toLowerCase() === "category") {
      itemType = "category";
    } else if (item.itemType && item.itemType.toLowerCase() === "shows") {
      itemType = "shows";
    }
    //itemType = "tv-shows";
    let moviePath = `${itemType}/${nodeId}/${title}`;
    let moviePathWithSlash = base + moviePath;
    if (!newUrl.endsWith("/")) {
      moviePath = moviePathWithSlash;
    }
    let baseUrl = document.location.origin;
    window.location.href = baseUrl + moviePath + qs;
  }
  function getCleanedTitle(str) {
    str = str.toLowerCase().split(" ").join("-");
    /*// Replace all non-alphanumeric characters except hyphens with an empty string
		str = str.replace(/[^\w-]+/g, '-');
		// Replace consecutive hyphens with a single hyphen
		str = str.replace(/-{2,}/g, '-');
		return str;*/
    // Remove all non-alphanumeric characters except hyphens
    str = str.replace(/[^\w-]/g, "");
    // Remove consecutive hyphens
    str = str.replace(/-{2,}/g, "-");
    // Remove trailing hyphen (if present)
    str = str.replace(/-$/, "");
    return str;
  }
}
export default Tile;
